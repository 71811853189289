<template>
    <bread-pages>
        <div class="main" v-loading="loading">
            <div class="main_con">
                <div class="ImgBox">
                    <el-carousel>
                        <el-carousel-item v-for="(item, index) in info.img" :key="index">
                            <el-image :src="getImg(item)" fit="fill"></el-image>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="main_con_title">
                    <div class="title">{{ info.name }}</div>
                    <div class="info">
                        <div class="info-item">
                            <span class="text1">申请号：</span>
                            <span class="text2">{{ info.code }}</span>
                        </div>
                        <div class="info-item" v-if="info.applyTime">
                            <span class="text1">申请日:</span>
                            <span class="text2">{{ info.applyTime }}</span>
                        </div>
                        <div class="info-item">
                            <span class="text1">所属分类：</span>
                            <span class="text2">{{ info.categoryName }}</span>
                        </div>
                        <div class="info-item">
                            <span class="text1">商标类型：</span>
                            <span class="text2">{{ getLabelName(info.logoType, logoTypeOptions) }}</span>
                        </div>

                        <div class="info-item">
                            <span class="text1">商标注册人：</span>
                            <span class="text2">{{ info.owner }}</span>
                        </div>
                        <div class="info-item">
                            <span class="text1">交易方式：</span>
                            <span class="text2">{{ getLabelName(info.tradingMethod, tradingMethodOptions) }}</span>
                        </div>
                        <div class="info-item">
                            <span class="text1">价格：</span>
                            <span class="text2" v-if="info.negotiateDirectly">面议 </span>
                            <span class="text2" v-else>{{ info.expectedPrice }}万元 </span>
                        </div>
                        <div class="info-item">
                            <span class="text1">核定使用商品/服务项目：</span>
                            <span class="text2">{{ showList(info.servicesItem, ';') }}</span>
                        </div>
                        <!-- <div class="info-item">
                        <span class="text1">底价：</span>
                        <span class="text2">{{ info.basePrice }}万元</span>
                    </div>
                    <div class="info-item">
                        <span class="text1">佣金类型：</span>
                        <span class="text2">{{ getLabelName(info.commissionType, commissionTypeOptions) }}</span>
                    </div>
                    <div class="info-item">
                        <span class="text1">佣金：</span>
                        <span class="text2">{{ info.commission }}万元</span>
                    </div> -->
                        <!-- <div class="info-item">
                        <span class="text1">联系人：</span>
                        <span class="text2">{{ info.contact }}</span>
                    </div> -->
                        <!-- <div class="info-item">
                        <span class="text1">电话：</span>
                        <span class="text2">{{ info.phone }}</span>
                    </div>
                    <div class="info-item">
                        <span class="text1">邮箱：</span>
                        <span class="text2">{{ info.email }}</span>
                    </div> -->
                        <!-- <div class="info-item">
                        <span class="text1">所在地区：</span>
                        <span class="text2">{{ info.address }}</span>
                    </div> -->
                    </div>
                    <div class="btn">
                        <div class="icon-button" @click.prevent="connectKefu">
                            <i class="iconfont iconfont-nav_icon_xiaoxi1"></i>
                            咨询
                        </div>
                        <div class="icon-button" @click="postMessage">
                            <i class="iconfont iconfont-nav_icon_xiaoxi"></i>
                            留言
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="content">
                <div class="sub-title">著录项</div>

                <div class="sub-title">商标图样</div> -->
            <!-- <div class="text">
                    <el-image v-for="(item, index) in info.img" :key="index" :src="getImg(item)" fit="cover"></el-image>
                </div> -->
            <!-- </div> -->
        </div>
    </bread-pages>
</template>

<script>
import BreadPage from '../../components/page/BreadPage.vue';
import BreadPages from '../../components/page/BreadPages.vue';
import comEvent from '../../mixins/comEvent';
import { logoTypeOptions, tradingMethodOptions, commissionTypeOptions } from '../../utils/variables';
export default {
    data() {
        return {
            info: {},
            logoTypeOptions,
            tradingMethodOptions,
            commissionTypeOptions,
            loading: true
        };
    },
    components: {
        BreadPages
    },
    mixins: [comEvent],
    mounted() {
        this.loading = true;
        this.$http.get('/logo/get/' + this.$route.query.id).then(res => {
            this.info = res;
            this.loading = false;
            this.$EventBus.$emit('changePreTitle', {
                name: '商标',
                path: '/logo'
            });
            this.$EventBus.$emit('changeTitle', '商标详情');
            document.title = '商标详情';
        });
    },
    created() {
        let addView = {
            id: this.$route.query.id,
            type: 'Logo'
        };
        // console.log(addView);
        this.loading = true;
        this.$http.get('/all/addView/', addView).then(res => {
            console.log(res);
        });
    }
};
</script>

<style lang="less" scoped>
// .main {
//     background-color: #fff;
//     padding: 0 30px 150px;
//     position: relative;
// }
.main_con {
    width: 1200px;
    height: 360px;
    background: #ffffff;
    border: 1px solid #f5f7fa;
    display: flex;
    .main_con_title {
        padding-top: 20px;
    }
}
/deep/ .el-carousel__container {
    height: 360px;
    width: 360px;
    margin-right: 50px;
}
/deep/ .el-image {
    width: 100%;
    height: 100%;
}
.title {
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    line-height: 33px;
    margin-bottom: 30px;
}

.sub-title {
    font-size: 16px;
    font-weight: bold;
    color: @warn;
    line-height: 22px;
    padding: 50px 0 16px;
    .flex();
    &::before {
        content: '';
        width: 5px;
        height: 16px;
        background: @warn;
        border-radius: 3px;
        margin-right: 10px;
    }
}

.info {
    .flex();
    flex-wrap: wrap;
    .info-item {
        width: 50%;
        padding-right: 50px;
        box-sizing: border-box;
        line-height: 32px;
        margin-bottom: 8px;
        .flex();

        .text1 {
            font-size: 14px;
            color: #999999;
            display: inline-block;
            min-width: 80px;
            flex-shrink: 0;
        }

        .text2 {
            font-size: 14px;
            color: #000;
            margin-left: 6px;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

.text {
    font-size: 14px;
    color: #000000;
    line-height: 20px;
    .flex-col();
    align-items: center;
}

.btn {
    margin-top: 37px;
    // text-align: center;
    .icon-button {
        width: 134px;
        height: 36px;
        border-radius: 2px;
        border: 1px solid #01a041;
        line-height: 36px;
        color: #01A041;
        justify-content: center;
        padding: 0px;
        cursor: pointer;
    }
    .icon-button:nth-of-type(1){
        margin-right: 20px;
    }
}
.appContainer {
    background: #ffffff !important;
}
</style>
